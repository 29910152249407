/*
*   Welcome.css
*   This css used to apply css efect on welcome component
*/

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75em;
  text-align: center;
  margin-top: 0.75em;
}

.header {
  font-size: 2em;
  font-weight: 700;
}

.caption {
  font-size: 1.15em;
}

.headerCaption {
  color: #fff;
}

.headerDescription {
  font-size: 0.55em;
  color: #f0c105;
}

.content {
  color: #fff;
  border: 2px solid #808085;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.instructions {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1rem 0;
}

.instruction {
  display: flex;
  gap: 1em;
}

.icon {
  padding-top: 0.3em;
}

.title {
  font-size: 1.15em;
  color: #f0c105;
}
